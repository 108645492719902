<template>
<v-container fluid fill-height>
    <v-card class="align-self-stretch pa-4 ma-2" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Регламентні документи </h1>
        </v-card-title>
        <v-card-subtitle class="pa-0 mt-2">
            <h4>Регламентні документи, що супроводжують процес сервісного обслуговування товарів, закуплених у нас.</h4>
        </v-card-subtitle>
        <v-container fluid>
            <v-row>
                <v-col col=12 sm=12 md=6>
                    <h4 class="mt-4">Процедури</h4>
                    <v-list v-for="(item, index) in procedure" :key="index" >
                        <v-list-item class="px-0">
                            <v-avatar>
                                <v-icon class="pr-4" color="primary">{{exclelIcon}}
                                </v-icon>
                            </v-avatar>
                            {{ item.name }}
                            <v-btn text class="px-0 ml-4" :href="item.href" title="Вивантажити">
                                <v-icon>get_app</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col col=12 sm=12 md=6>
                    <h4 class="mt-4">Шаблони документів</h4>
                    <v-list v-for="(item, index2) in docs" :key="index2">
                        <v-list-item class="px-0">
                            <v-avatar>
                                <v-icon class="pr-4" color="primary">{{exclelIcon}}
                                </v-icon>
                            </v-avatar>
                            {{ item.name }}
                            <v-btn text class="px-0 ml-4" :href="item.href" title="Вивантажити">
                                <v-icon>get_app</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
        <p>
            Перевірити вимоги гарантійного обслуговування по товарам каталогу
            <v-btn text class="px-0" href="/service-help" title="Перейти">
                <v-icon color="link">launch</v-icon>
            </v-btn>
        </p>
    </v-card>
</v-container>
</template>

<script>
import {
    mdiMicrosoftExcel
} from '@mdi/js'

export default {
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
    data: () => ({
        exclelIcon: mdiMicrosoftExcel,
        procedure: [{
                name: 'Сервісні процедури з ремонту товару й компенсації браку',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Service-Yugcontract.7z',
            },
            {
                name: 'Процедура спрощеної компенсації товару обмінної гарантії',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/remote-recycling.zip',
            },
            {
                name: 'Умови списання товару з актами АСЦ',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Spisanie_tovara.xlsx',
            },
            {
                name: 'ПОСТАНОВА від 16 березня 2017 р. N 231',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/231-Decree.pdf',
            },
            {
                name: 'Умови, необхідні для компенсації кнопкових мобільних телефонів',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/compensation-mobile-phones.pdf',
            },
            {
                name: 'Пам\'ятка покупцеві смартфонів Neffos',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Neffos-service.pdf',
            },
            {
                name: 'З 01.08.2020 змінено умови прийому техніки Philips PERSONAL HEALTH',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Akt-defektacii.doc',
            },
            {
                name: 'Предторгове обслуговування Electrolux',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Electrolux.zip',
            },
            {
                name: 'Сервісне обслуговування ТМ Realme',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/Realme.zip',
            },
            {
                name: 'Сервісне обслуговування ТМ TECNO',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/TECNO.zip',
            },
            {
                name: 'Загальна сервісні інформація по групі Обігрівачі',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/docs/servis-obogrevateli.xlsx',
            },
        ],
        docs: [{
                name: 'Претензія (товар в СЦ, компенсація на р/р)',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/blanks/sc1.docx',
            },
            {
                name: 'Претензія (товар в СЦ, компенсація на баланс)',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/blanks/sc2.docx',
            },
            {
                name: 'Претензія (товар повернуто, компенсация на р/р)',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/blanks/wh1.docx',
            },
            {
                name: 'Претензія (товар повернуто, компенсация на баланс)',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/blanks/wh1.docx',
            },
            {
                name: 'Супровідна картка виробу (PickUp service)',
                href: 'https://b2b.yugcontract.ua/fileslibrary/service/blanks/Pick-Up-service.xlsx',
            },
        ],
    })
}
</script>
