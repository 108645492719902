<template>
    <ServiceDocsPage />
</template>

<script>
import ServiceDocsPage from '../components/ServiceDocsPage.vue'

export default {
    components: {
        ServiceDocsPage
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
}
</script>

